
import React from 'react'
import {
  motion as m,
  // useMotionValue,
  useSpring,
  useTransform,
  useViewportScroll
} from 'framer-motion';

export default function PParticles() {
  const { scrollYProgress } = useViewportScroll()

  const springConfig = {stiffness: 10, damping: 25};
  const makeMultiplier = x => y => y * x * -1000;

  const yVal1 = useSpring(useTransform(scrollYProgress, makeMultiplier(2)), springConfig);
  const yVal2 = useSpring(useTransform(scrollYProgress, makeMultiplier(1.5)), springConfig);
  const yVal3 = useSpring(useTransform(scrollYProgress, makeMultiplier(0.5)), springConfig);

  return (
    <g>
      <m.polygon style={{ translateY: yVal3 }} points="467.76 168.01 436.41 145.31 433.64 184.16 467.76 168.01" fill="#e72958"/>
      {/* <m.rect style={{ translateY: yVal3 }}x="220.55" y="2.07" width="10" height="10" transform="translate(71.06 -157.42) rotate(45)" fill="#e72958"/> */}
      <m.path style={{ translateY: yVal3 }} d="M326.29 91.81a12.37 12.37 0 01-24.16 5.35" fill="#39abe2"/>
      {/* <m.rect style={{ translateY: yVal2 }} x="678.24" y="316.2" width="16.99" height="16.99" transform="translate(-28.46 580.7) rotate(-45)" fill="#e72957"/> */}
      <m.polygon style={{translateY: yVal3 }} points="370.85 381.73 347.06 373.99 353.07 398.44 370.85 381.73" fill="#e72957"/>
      {/* link */}
      <m.path style={{ translateY: yVal1 }} d="M652.38 263.05h0a5.33 5.33 0 01.25-7.5l4.79-4.47a5.32 5.32 0 017.5.25h0a5.32 5.32 0 01-.25 7.5l-4.79 4.47a5.32 5.32 0 01-7.5-.25zm22.83-13.39l4.79-4.47a5.33 5.33 0 00.25-7.5h0a5.32 5.32 0 00-7.5-.26l-4.75 4.48a5.33 5.33 0 00-.25 7.5h0a5.33 5.33 0 007.46.25zm-14.77 6l11.14-10.87" fill="none" stroke="#e72958" stroke-miterlimit="10" stroke-width="1.64px"/>
      {/* lightbulb */}
      <m.path style={{ translateY:yVal2 }} d="M14.38 380.9v1.8a1.61 1.61 0 01-1.52 1.67h-1.18a1.62 1.62 0 01-1.53-1.67v-1.8m6.7-7.59H7.14m12.91-2.94s-.87.72-1 .84a4.75 4.75 0 00-1.86 3.32 21.28 21.28 0 000 3.55 2.83 2.83 0 01-2.41 2.57 18.84 18.84 0 01-2.49.06 17.69 17.69 0 01-2.41-.06 2.83 2.83 0 01-2.41-2.57s.06 1.3.06-.57c0-1 0-2.08-.06-3a4.75 4.75 0 00-1.82-3.29c-.2-.14-1.08-.87-1.08-.87a11.45 11.45 0 1115.51 0z" fill="none" stroke="#e72958" stroke-miterlimit="10" stroke-width="1.7px"/>
    </g>

  )
}
