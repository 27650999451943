/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"

function SEO(props) {
  const title = 'Howler Co';
  const lang = 'en';

  return (
    <Helmet
      defaultTitle={title}
      titleTemplate={`%s | ${title}`}
      {...props}
    >
      <html lang={lang} />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Howler Co",
          "url": "TEMP",
          "logo": "TEMP/images/logo.png",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "TEMP",
            "contactType": "customer service",
            "areaServed": "AU",
            "availableLanguage": "en"
          },
          "sameAs": [
            "https://www.facebook.com/TEMP",
            "https://twitter.com/TEMP",
            "https://www.instagram.com/TEMP",
            "https://www.youtube.com/channel/TEMP"
          ]
        })}
      </script>
      { props.children }
    </Helmet>
  )
}

export default SEO
