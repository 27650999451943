import React from 'react';
import Slider from 'react-slick';
import cNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import ReactMd from 'react-markdown';

import {
  FeatureBanner,
  FeatureBlock as FBlock,
  BtnCta,
} from 'components';
import createScrollToHandler from 'utils/scrollToHandler';

import illustrations from './illustrations';

const docToReactOpts = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => children
  },
};

const sliderOpts = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

const TextBlock = props => {
  const { data, id, style } = props;

  const content = documentToReactComponents(data.fields.content);

  return (
    <section id={id} className="page-section" style={style}>
      <div className="text-banner-2-col block-mw">
      <h2 className="text-banner-2-col__heading-text h1">
        <ReactMd
          source={data.fields.title}
          allowedTypes={[
            'emphasis',
            'strong',
            'text',
          ]}
          unwrapDisallowed
        />
      </h2>
        <article className="text-banner-2-col__text">
          {content}
        </article>
      </div>
    </section>
  );
};

const TextBanner = props => {
  const { data, id, style } = props;

  const linkConds = data.fields.ctaLink.indexOf('#contact') !== -1;

  const ctaAttrs = {
    className: "btn-cta--outline-01",
    isLink: true,
    to: linkConds ? '#contact' : data.fields.ctaLink,
    onClick: linkConds ? createScrollToHandler({to: '#contact', offset: {top: 70}}) : null,
    children: data.fields.ctaText
  };

  return (
    <section className="text-banner" id={id} style={style}>
      <div className="text-banner__inner">
      <h2 className="text-banner__heading h1" style={{ color: 'inherit' }}>{data.fields.title}</h2>
        <article className="text-banner__text-wrapper">
          { documentToReactComponents(data.fields.content) }
        </article>
        <BtnCta {...ctaAttrs} />
      </div>
    </section>
  );
};

const FeatureBlock = props => {
  const { data, id, style } = props;

  const { mainContent, subfeatures } = data.fields;

  return (
    <section className="page-section" id={id} style={style}>
      <FeatureBanner isReverse={mainContent.isImgOnRight} className="block-mw">
        {{
          img: illustrations[mainContent.imgId],
          subtitle: mainContent.title,
          heading: documentToReactComponents(mainContent.heading, docToReactOpts),
          content: documentToReactComponents(mainContent.content)
        }}
      </FeatureBanner>

      { subfeatures.length > 0
        ? (
          <div className="feature-grid feature-grid--2-col block-mw">
            { subfeatures.map((item, idx) => (
              <div key={`s1-ftrs-${idx}`}>
                <FBlock>
                  {{
                    img: illustrations[item.imgId],
                    heading: item.title,
                    content: documentToReactComponents(item.content),
                  }}
                </FBlock>
              </div>
            )) }
          </div>
        ) : ''
      }
    </section>
  );
};

const FeatureList = props => {
  const { data, id, style } = props;

  return (
    <section id={id} className="page-section" style={style}>
      <div className="block-mw">
        <h2 className="h1" style={{marginTop: 0,marginBottom: '1em'}}>
          <ReactMd
            source={data.fields.title}
            allowedTypes={[
              'emphasis',
              'strong',
              'text',
            ]}
            unwrapDisallowed
          />
        </h2>
        <div className="feature-grid feature-grid--3-col">
          { data.fields.items.map((item, idx) => (
            <div key={`hpfs-${item.id}-${idx}`}>
              <FBlock className="feature-block--alt-01">
                {{
                  img: illustrations[item.imgId],
                  heading: item.title,
                  content: documentToReactComponents(item.content),
                }}
              </FBlock>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Showcase = props => {
  const { data, id, style } = props;

  return (
    <section className="page-section" style={style} id={id}>
      <div className="block-mw">
        <h2 className="h1" style={{ marginTop: 0, marginBottom: '1em' }}>
          <ReactMd
            source={data.fields.title}
            allowedTypes={[
              'emphasis',
              'strong',
              'text',
            ]}
            unwrapDisallowed
          />
        </h2>
        <div className={cNames({
          'brands-showcase-wrapper': true,
          'brands-showcase-wrapper--carousel': data.fields.items.length >= 6,
          'brands-showcase-wrapper--grid': data.fields.items.length < 6
        })}>
          { data.fields.items.length >= 6
            ? (
              <Slider {...sliderOpts} >
                { data.fields.items.map((item, idx) => (
                  <div className="brand-logo-wrapper" key={`hpb-${item.id}-${idx}`}>
                    <img src={item.url} alt={item.name}/>
                  </div>
                )) }
              </Slider>
            )
            : (
              <>
                { data.fields.items.map((item, idx) => (
                  <div className="brand-logo-wrapper" key={`hpb-${item.id}-${idx}`}>
                    <img src={item.url} alt={item.name}/>
                  </div>
                )) }
              </>
            )
          }
        </div>
      </div>
    </section>
  );

};


const HP = {
  TextBlock,
  TextBanner,
  FeatureBlock,
  FeatureList,
  Showcase
}

export default HP;


