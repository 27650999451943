import React from 'react';
import { Link } from 'gatsby';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Obfuscate from 'react-obfuscate';

import createScrollToHandler from 'utils/scrollToHandler';

import './footer.scss';

export default function Footer({ data }) {
  const {
    heading,
    socialMediaLinks,
    content,
    ctaText,
    ctaLink
  } = data.fields;
  const links = data.links;
  const currentPath = data.location.pathname;

  let socialMedias;

  if (socialMediaLinks == null) {
    socialMedias = [];
  } else {
    socialMedias = socialMediaLinks.filter((item) => item.hasOwnProperty('fields'))
      .map(({fields}) => ({ type: fields.type, url: fields.url }));
  }

  return (
    <footer className="page-footer" id="contact">
      <div className="page-footer__row page-footer__row--contact">
        {/* eslint-disable-next-line */}
        <a className="page-footer__back-to-top"
          href="#"
          onClick={ createScrollToHandler({to : {top : 0}}) }
        >
          <i className="page-footer__back-to-top-icon zmdi zmdi-long-arrow-up"></i>
        </a>
        <div className="page-footer-contact">
          <h2 className="page-footer-contact__heading h1">{heading}</h2>
          <article className="page-footer-contact__text-wrapper">
            {documentToReactComponents(content, {
              renderNode: {
                [INLINES.HYPERLINK]: (node, children) => {
                  if (node.data.uri.indexOf('mailto') !== -1) {
                    return <Obfuscate href={node.data.uri} >{children}</Obfuscate>
                  } else {
                    return <a href={node.data.uri}>{children}</a>;
                  }
                }
              }
            })}
          </article>
          <Obfuscate
            href={ctaLink}
            className="btn-cta btn-cta--outline-02 page-footer-page-footer-contact__cta"
          >{ctaText}</Obfuscate>
        </div>
      </div>

      <div className="page-footer__row page-footer__row--social">
        <ul className="page-footer-social-links">
          {socialMedias.map((i, idx) => {
            let iconClass = 'social-icon-cta__icon'

            switch (i.type) {
              case 'facebook':
                iconClass = 'social-icon-cta__icon zmdi zmdi-facebook';
                break;
              case 'twitter':
                iconClass = 'social-icon-cta__icon zmdi zmdi-twitter';
                break;
              case 'instagram':
                iconClass = 'social-icon-cta__icon zmdi zmdi-instagram';
                break;
              default:
                break;
            }
            return (
              <li key={`sm-link-${idx}`}>
                <a className="social-icon-cta" href={i.url}>
                  <i className={iconClass}></i>
                </a>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="page-footer__row page-footer__row--nav">
        <div className="page-footer-nav">
          <ul className="page-footer-nav__links">
            { links.map((i, idx) => {
              const conds = currentPath === i.to.path || i.to.hash === '#contact';
              let link = null;

              if (i.isInternal) {
                if (conds) {
                  link = React.createElement(
                    'a',
                    {
                      href: i.to.hash,
                      onClick: createScrollToHandler({
                        to: i.to.hash,
                        offset: {top: 70}
                      })
                    },
                    i.title
                  );
                } else {
                  link = React.createElement(
                    Link,
                    { href: i.to.path + i.to.hash },
                    i.title
                  );
                }
              } else {
                link = React.createElement(
                  'a',
                  { href: i.to },
                  i.title
                );
              }

              return <li key={`flink-${idx}`}children={link} />
            }) }
          </ul>
          <div className="page-footer-nav__copy">&#169; Howler. All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  )
}


