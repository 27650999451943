import React from 'react';
import "./btn-cta.scss";

export default function BtnCta(props) {
  const attributes = {
    href: props.isLink ? props.to : null,
    className : `btn-cta ${props.className}`,
    onClick: props.onClick ? (e) => { props.onClick(e) }: null
  }

  if (props.isLink) {
    return (
      <a {...attributes} >
        {props.children}
      </a>
    );
  } else {
    return (
      <button {...attributes}>
        {props.children}
      </button>
    );
  }
}
