import React from 'react';

import PBChart from './parts-bar-chart';
import PBrowser from './parts-browser';
import PMGlass from './parts-magnifying-glass';
import PParticles from './parts-particles';
import PPerson from './parts-person';
import PTablet from './parts-tablet';

export default function HeroSvg(props) {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 698.75 429.59">
      <defs/>
      <PBChart />
      <PBrowser />
      <PTablet />
      <PMGlass />
      <PPerson />
      <PParticles />
    </svg>
  );
}
