import React from 'react';

import Section1Img from 'assets/svg/section-1.svg';
import Section2Img from 'assets/svg/section-2.svg';
import Section3Img from 'assets/svg/section-3.svg';
import Section4Img from 'assets/svg/section-4.svg';

import iconBulb from 'assets/svg/icon-bulb.svg';
import iconGlass from 'assets/svg/icon-glass.svg';
import iconLink from 'assets/svg/icon-link.svg';
import iconPhone from 'assets/svg/icon-phone.svg';

import ilChart from 'assets/svg/il-chart.svg';
import ilCoin from 'assets/svg/il-coin.svg';
import ilGlass from 'assets/svg/il-glass.svg';

const illustrations = {
  'icon-bulb': <img src={iconBulb} alt="icon" />,
  'icon-link': <img src={iconLink} alt="icon" />,
  'icon-mglass': <img src={iconGlass} alt="icon" />,
  'icon-phone': <img src={iconPhone} alt="icon" />,

  'illustration-chart': <img src={ilChart} alt="illustration" />,
  'illustration-coin': <img src={ilCoin} alt="illustration" />,
  'illustration-seo': <img src={ilGlass} alt="illustration" />,

  'illustration-analytics': <img src={Section4Img} alt="illustration" />,
  'illustration-content': <img src={Section2Img} alt="illustration" />,
  'illustration-optimisation': <img src={Section3Img} alt="illustration" />,
  'illustration-organic': <img src={Section1Img} alt="illustration" />,
}

export default illustrations;
