import React from 'react'
import {
  motion as m,
} from 'framer-motion';

import './styles.scss';

export default function PTablet() {
  const svgPopIn = {
    hidden: {
      scale: 0,
      opacity: 0.8
    },
    loaded: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1.2,
        type: "spring",
        delay: 1.3
      }
    }
  };

  return (
    <m.g
      variants={svgPopIn}
      initial="hidden"
      animate="loaded"
      style={{ translateY: '-2.4%'}}
    >
      {/* tablet shadow */}
      <g className="hsvg-tablet-shadow">
        <rect x="223.19" y="300.45" width="78.87" height="122.65" rx="5.52" transform="rotate(13.82 262.67741 361.89082)" fill="#e72958" opacity=".15"/>
      </g>
      <g className="hsvg-tablet">
        <rect x="217.69" y="287.45" width="78.87" height="125.58" rx="5.52" transform="rotate(13.82 257.17419 350.33366)" fill="#fff" stroke="#e72958" strokeMiterlimit="10" strokeWidth=".806120693683624px"/>
        <rect x="238.02" y="300.2" width="60.73" height="9.11" transform="rotate(13.82 268.4484 304.84336)" fill="#e0e0e0"/>
        <rect x="226.15" y="337.29" width="13.06" height="23.34" transform="rotate(13.82 232.7399 349.06666)" fill="#f0f0f0"/>
        <path d="M242.55 358.48l31.86 7.78-.22.9-32.07-7.84.21-.89zM279 339.09l.22-.9-47.12-11.51-.21-.05-.22.89L278.76 339zm-35 12.61l21.09 5.15.22-.89-21.09-5.15zm20.8-25.1l-30.85-7.54-.21-.05-.22.9 31.06 7.58zm29.42-.89l-.21.9-.22-.05-58.41-14.27.22-.9.21.06zm-48.14 17.48l-.22.89 39.49 9.65h.21l.22-.89-39.49-9.65zm-9.27 37.11l21.1 5.15.22-.89L237 379.4zm2.08-8.52l-.21.9 39.7 9.7.21-.9-39.48-9.64z" fill="#cdcccc"/>
        <rect x="219.44" y="365.95" width="13.06" height="19.35" transform="rotate(13.82 226.00564 375.71248)" fill="#f0f0f0"/>
        <line x1="228.52" y1="397.93" x2="258.9" y2="405.52" fill="none" stroke="#e72958" strokeMiterlimit="10" strokeWidth=".876513242713649px"/>
      </g>
    </m.g>
  )
}
