import React from 'react'
import {
  motion as m,
} from 'framer-motion';

export default function PBrowser() {

  const svgPopIn = {
    hidden: {
      scale: 0,
      opacity: 0.8
    },
    loaded: i => ({
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1.2,
        type: "spring",
        delay: 1.5 + (0.1 * i)
      }
    })
  };

  return (
    <>
      {/* pie chart */}
      <g fill="#eaeaea">
        <path d="M656.56 155.83a21.91 21.91 0 11-3.8-30.74 21.91 21.91 0 013.8 30.74z" opacity=".5"/>
        <path d="M636.05 120.7h-.21l3.59 21.44 20.83 6.25a21.75 21.75 0 00-24.21-27.73z" opacity=".5"/>
      </g>
      {/* browser frame */}
      <m.g
        custom={0}
        variants={svgPopIn}
        initial="hidden"
        animate="loaded"
      >
        <path d="M632.46 168.31v-9.75c0-4.77-3.62-7.94-8.7-7.66H283.65c-5.07-.5-8.69 2.89-8.69 7.66v9.75h0v231.86h357.5V168.31z" fill="#fff" stroke="#000" strokeMiterlimit="10"/>
        <circle cx="285.84" cy="159.68" r="1.94" fill="#222"/>
        <circle cx="295.14" cy="159.68" r="1.94" fill="#e72958"/>
        <circle cx="304.44" cy="159.68" r="1.94" fill="#39abe2"/>
        <line x1="274.96" y1="167.63" x2="632.46" y2="168.95" fill="#fff" stroke="#010101" strokeMiterlimit="10"/>

        {/* browser content 1 */}
        <m.g
          custom={2}
          variants={svgPopIn}
          initial="hidden"
          animate="loaded"
        >
          <path d="M425.55 199h-125.3v-.82h125.3zm-49.3 12.47h-76v.81h76zm29.1 13.29h-105.1v.81h105.1z" fill="#010101"/>
          <rect id="button" x="300.25" y="241.8" width="38.87" height="10.59" rx="5.29" fill="#39abe2"/>
        </m.g>

        {/* broswer content 2 */}
        <m.g
          custom={3}
          variants={svgPopIn}
          initial="hidden"
          animate="loaded"
        >
          <rect x="465.7" y="193.31" width="136.03" height="57.97" rx="12.99" fill="#eaeaea" opacity="0.7" />
        </m.g>

        {/* browser content 3 */}
        <m.g
          custom={4}
          variants={svgPopIn}
          initial="hidden"
          animate="loaded"
        >
          <rect x="300.25" y="278.41" width="124.37" height="110.23" rx="8.24" fill="#f4f3f4"/>
          <rect x="379.53" y="327.12" width="13.5" height="12.8" fill="#ddd"/>
          <circle cx="361.57" cy="333.52" r="6" fill="#ddd"/>
          <rect x="333.91" y="328.52" width="10" height="10" transform="rotate(45 338.91088 333.52148)" fill="#ddd"/>
        </m.g>

        {/* browser content 4 */}
        <m.path d="M543.61 283.66h-76.94v-.78h76.94zm-36.45 12h-40.49v.79h40.49zm21.52 12.76h-62v.79h62zm14.93 14h-76.94v.78h76.94zm-36.45 12.76h-40.49v.79h40.49zm21.52 12.76h-62v.79h62zm14.93 13.95h-76.94v.79h76.94zm-36.45 12.76h-40.49v.79h40.49zm21.52 12.77h-62v.78h62z" fill="none" stroke="#9a9999" strokeMiterlimit="10" strokeWidth="0.72px"
          custom={5}
          variants={svgPopIn}
          initial="hidden"
          animate="loaded"
        />
      </m.g>
    </>
  )
}
