import React from 'react'
import cName from 'classnames';

import "./feature-banner.scss";

export default function FeatureBanner(props) {
  const { children } = props;

  const classes = cName({
    'feature-banner': true,
    'feature-banner--reverse': props.isReverse === true,
    [props.className] : props.className != null
  });

  return (
    <div className={classes}>
      <figure className="feature-banner__img-wrapper">
        { children.img }
      </figure>
      <div className="feature-banner__text-wrapper">
        <h2 className="feature-banner__subtitle">{ children.subtitle }</h2>
        <div className="feature-banner__heading h1">{ children.heading }</div>
        <article className="feature-banner__text">
          { children.content }
        </article>
      </div>
    </div>
  )
}
