
import React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";

import '../styles/main.scss';
import Header from "../components/header/";
import Footer from "../components/footer/";

/*
 * helper function for getting nav link from contentful object
 */
const getNavLinks = (i) => {
  let isInternal = false;

  const rawLink = i.fields.to;
  let link = rawLink;

  // if link starts with / it is an internal link
  if (rawLink[0] === '/' ) {
    let hashIndex = rawLink.indexOf('#');
    const conds = hashIndex > -1;
    isInternal = true;

    link = {
      path: conds ? rawLink.slice(0, hashIndex) : rawLink,
      hash: conds ? rawLink.slice(hashIndex) : ''
    }
  } 

  return {
    isInternal,
    title: i.fields.title,
    to: link
  }
};

/**
*/
const LayoutBase = ({ children, layoutProps }) => {
  const { footerLinks: footerLinksRaw, headerLinks: headerLinksRaw } = layoutProps.pageNavData.fields;

  const footerLinks = footerLinksRaw.map(getNavLinks);
  const headerLinks = headerLinksRaw.map(getNavLinks);

  return (
    <>
      <Header data={{ location: layoutProps.location, links: headerLinks }} />
      <main>
        { children }
      </main>
      <Footer data={{ ...layoutProps.footerData, links: footerLinks, location: layoutProps.location }}/>
    </>
  )
}

LayoutBase.propTypes = {
  children: PropTypes.node.isRequired,
}

export { LayoutBase }
