/**
 * @name createScrollToHandler
 * @function create click handler for scrolling to, currently using window.scrollTo api
 * @param {Object} props object containing the settings for handler
 *   - props.to - can be either a string or object containing top and left value
 *   - props.offset - object with top, left as key for top and left scroll offset
 */
export default function createScrollToHandler(props) {
  const defaultOffset = {
    top: 0,
    left: 0
  };

  if (props.offset == null) {
    props.offset = defaultOffset;
  }

  if (typeof(props.to) === 'object') {
    return (e) => {
      if (window.scrollTo) {
        e.preventDefault();

        window.scrollTo({
          top: props.to.top || 0,
          left: props.to.left || 0,
          behavior: 'smooth'
        });
      }

      e.currentTarget.blur();
    };
  } else if (typeof(props.to) === 'string') {
    return (e) => {
      if (window.scrollTo) {
        let top, left, rect;

        e.preventDefault();

        let target = document.getElementById(props.to.replace('#', ''));

        // if target is found, get target position
        if (target) {
          let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

          rect = target.getBoundingClientRect();
          top = rect.top + scrollTop - props.offset.top;
          left = rect.left + scrollLeft - props.offset.left;
        } else {
          top = 0;
          left = 0;
        }

        window.scrollTo({
          top: top,
          left: left,
          behavior: 'smooth'
        });

        e.currentTarget.blur();
      }
    }
  }
}
