import React from "react";

export default function LogoHowler(props) {
  return (
    <svg width={125} height={26} viewBox="0 0 125 26" fill="none" {...props}>
      <path
        className="logo-o"
        d="M36.6666 23.7149C35.3024 23.7286 33.9492 23.4748 32.6869 22.9683C31.5008 22.4955 30.423 21.7959 29.5165 20.9104C28.6117 20.0171 27.903 18.9523 27.4338 17.7813C26.9233 16.5238 26.6687 15.1808 26.6843 13.8277C26.6678 12.4725 26.9224 11.1272 27.4338 9.86769C27.897 8.69472 28.6027 7.62799 29.5066 6.73432C30.4105 5.84065 31.4932 5.1393 32.6869 4.67419C35.2472 3.70276 38.0859 3.70276 40.6462 4.67419C41.8363 5.13365 42.9159 5.83 43.8166 6.71913C44.715 7.61614 45.4209 8.68006 45.8927 9.84822C46.3987 11.109 46.651 12.4537 46.6355 13.8083C46.6511 15.1607 46.3988 16.5033 45.8927 17.7618C45.4209 18.93 44.715 19.9939 43.8166 20.8909C42.9148 21.782 41.8357 22.4824 40.6462 22.9488C39.3846 23.4598 38.0319 23.7202 36.6666 23.7149ZM36.6666 19.0407C37.3526 19.05 38.0321 18.9103 38.6564 18.6317C39.2311 18.3765 39.7409 17.9995 40.1487 17.5281C40.5649 17.039 40.8783 16.4742 41.0707 15.8662C41.4953 14.5456 41.4953 13.1293 41.0707 11.8088C40.8768 11.2013 40.5637 10.6368 40.1487 10.1468C39.7443 9.67408 39.2396 9.29307 38.6697 9.03024C38.0404 8.76847 37.3636 8.63356 36.6798 8.63356C35.9961 8.63356 35.3193 8.76847 34.69 9.03024C34.1056 9.28528 33.5851 9.66189 33.1645 10.1339C32.7496 10.6238 32.4364 11.1884 32.2426 11.7958C31.818 13.1163 31.818 14.5327 32.2426 15.8532C32.4334 16.4658 32.7468 17.0352 33.1645 17.5281C33.5685 18.0013 34.0733 18.3824 34.6436 18.6447C35.279 18.9235 35.9704 19.0589 36.6666 19.0407Z"
        fill="#D1D3D4"
      />
      <path
        className="logo-w"
        d="M47.5508 4.46643H52.9365L55.4105 17.0672L58.4351 4.92086H63.0779L66.129 17.0672L68.5765 4.46643H73.7898L69.0076 23.2929H63.6749L60.6504 11.3738L57.6259 23.2669H52.2136L47.5508 4.46643Z"
        fill="#D1D3D4"
      />
      <path
        className="logo-l"
        d="M75.791 4.46643H80.9181V18.8719H89.8126V23.2669H75.8175L75.791 4.46643Z"
        fill="#D1D3D4"
      />
      <path
        className="logo-e"
        d="M92.0879 4.46643H105.91V8.78353H97.1884V11.6854H104.358V15.7428H97.1884V18.9563H106.514V23.2669H92.0879V4.46643Z"
        fill="#D1D3D4"
      />
      <path
        className="logo-r"
        d="M109.16 4.4664H117.119C118.07 4.45646 119.016 4.59003 119.925 4.8624C120.704 5.10719 121.424 5.5048 122.041 6.03094C122.648 6.56839 123.123 7.2345 123.427 7.97851C123.767 8.83023 123.931 9.73913 123.911 10.6532C123.943 11.789 123.656 12.9116 123.082 13.8991C122.539 14.7982 121.776 15.5503 120.86 16.0869L124.979 23.2994H119.507L116.277 16.8789H114.141V23.2799H109.16V4.4664ZM116.622 12.9838C116.926 12.9898 117.228 12.9354 117.509 12.8238C117.791 12.7122 118.046 12.5458 118.26 12.3346C118.48 12.1241 118.653 11.8711 118.767 11.5916C118.881 11.3121 118.934 11.0124 118.923 10.7116C118.957 10.0932 118.74 9.48686 118.32 9.0237C118.098 8.80463 117.832 8.63363 117.538 8.52179C117.244 8.40995 116.93 8.35977 116.615 8.37451H114.168V12.9188L116.622 12.9838Z"
        fill="#D1D3D4"
      />
      <g className="logo-h">
        <path
          d="M22.704 0.311611V0H14.9238V7.92659L22.704 0.311611Z"
          fill="#EE2A7B"
        />
        <path
          d="M13.7231 9.10162H7.747V0H0V22.5333L13.7231 9.10162Z"
          fill="#EE2A7B"
        />
        <path d="M1.75781 26H7.74715V20.1378L1.75781 26Z" fill="#EE2A7B" />
        <path
          d="M10.1406 17.7943H14.9228V26H22.703V5.50513L10.1406 17.7943Z"
          fill="#EE2A7B"
        />
      </g>
    </svg>
  );
}
