import React from 'react';
import {
  motion as m,
} from 'framer-motion';

export default function PMGlass() {
  const svgBar = {
    hidden: {
      scaleY: 0,
    },
    loaded: i => ({
      scaleY: 1,
      transition: {
        duration: 0.7 + 0.2 * i,
        delay: 0.3 * i,
      }
    })
  };

  return (
    <g>
      <m.g
        style={{ originY: 1 }}
        variants={svgBar}
        custom={0}
        initial="hidden"
        animate="loaded"
      >
        <rect x="13.26" y="335.58" width="29.11" height="64.62" fill="#444"
        />
      </m.g>
      <m.g
        style={{ originY: 1 }}
        variants={svgBar}
        custom={1}
        initial="hidden"
        animate="loaded"
      >
        <rect x="61.56" y="295.17" width="29.11" height="105.03" fill="#282929" />
      </m.g>
      <m.g
        style={{ originY: 1 }}
        variants={svgBar}
        custom={2}
        initial="hidden"
        animate="loaded"
      >
        <rect x="109.85" y="268.06" width="29.11" height="132.14" fill="#444" />
      </m.g>

      <m.g
        style={{ originY: 1 }}
        variants={svgBar}
        custom={3}
        initial="hidden"
        animate="loaded"
      >
        <rect x="158.14" y="238" width="29.11" height="162.2" fill="#2e2f2f" opacity="0.7" />
      </m.g>
    </g>
  )
}
