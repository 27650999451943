import React, { Component } from 'react';
import { Link } from "gatsby";
import { motion as m } from "framer-motion";

import createScrollToHandler from 'utils/scrollToHandler';
import Logo from './logo';

import "./header.scss";

/**
 * @name Header
 *
 * accepts links that would be listed in the header as children
 */
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this._bp = null;
  }

  componentDidMount() {
    this._bp = window.matchMedia('(min-width: 992px)');
    const checkBp = () => {
      if (this._bp.matches) {
        this.setState({ isOpen: true });
      } else {
        this.setState({ isOpen: false });
      }
    };

    this._bp.addListener(checkBp);

    checkBp();
  }

  toggleOpen () {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render (){
    const { data }  = this.props;
    const currentPath = data.location.pathname;
    const links = data.links;


    const ulVars = {
      hidden: {
      },
      visible: {
        transition: {
          staggerChildren: 0.12,
          delayChildren: 0.3
        }
      }
    }

    const liVars = {
      hidden: {
        opacity: 0,
        translateX: '-5%',
        transition: 'easeOut'
      },
      visible: {
        opacity: 1,
        translateX: '0%',
        transition: 'easeOut'
      }
    };

    const liAttrs = {
      initial:"hidden",
      onClick: () => {
        if(!this._bp.matches) { this.setState({ isOpen: false }); }
      },
      variants: liVars
    }


    return (
      <header className={`page-header ${this.state.isOpen ? 'is-open' : ''}`}>
        <div className="page-header__inner">
          <div className="page-header__mobile-header">
            { currentPath === '/'
              ? (
                <a className="page-header__logo-wrapper" href="#0" onClick={createScrollToHandler({to:{top:0}})}>
                  <Logo className="howler-logo"/>
                </a>
              )
              : (
                <Link className="page-header__logo-wrapper" to="/">
                  <Logo className="howler-logo"/>
                </Link>
              )
            }
            <div className="page-header__mobile-cta-wrapper">
              <button className="page-header__mobile-toggle-cta" onClick={this.toggleOpen.bind(this)}>
                { this.state.isOpen
                  ? <i className="icon zmdi zmdi-close"></i>
                  : <i className="icon zmdi zmdi-menu"></i>
                }
              </button>
            </div>
          </div>
          <m.ul
            className="page-header__link-list"
            animate={ this.state.isOpen ? 'visible': 'hidden'}
            variants={ulVars}
          >
            { links.map((i,idx) => {
              const conds = currentPath === i.to.path || i.to.hash === '#contact';
              let link = null;

              if (i.isInternal) {
                if (conds) {
                  link = React.createElement(
                    'a',
                    {
                      href: i.to.hash,
                      onClick: createScrollToHandler({
                        to: i.to.hash,
                        offset: {top: 70}
                      })
                    },
                    i.title
                  );
                } else {
                  link = React.createElement(
                    Link,
                    { href: i.to.path + i.to.hash },
                    i.title
                  );
                }
              } else {
                link = React.createElement(
                  'a',
                  { href: i.to },
                  i.title
                );
              }

              return <m.li {...liAttrs} key={`hlink-${idx}`} children={link} />
            }) }
          </m.ul >
        </div>
      </header>
    );
  }
}

export default Header;
