import React from 'react';

export default function PPerson() {
  return (
    <g>
      {/* left leg bg */}
      <polyline points="523.26 226.25 533.4 394.01 511.39 404.73 547.76 403.39 549.94 261.5 548.99 254.15 542.21 241.73 531.6 227.93 529.02 224.14 524.01 224.44" fill="#f4f3f4"/>
      {/* left leg outline */}
      <polyline points="549.84 258.72 547.54 403.31 511.17 404.65 533.18 393.93 523.06 224.22" fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth=".91px"/>
      {/* head */}
      <path d="M544.94 128.62a11.89 11.89 0 01-15.06-12.15c.12-2.12.81-4.21.57-6.32-.13-1.12-1-1.73-2-2.3a1.92 1.92 0 01.08-3.33 10 10 0 012.49-1 4.08 4.08 0 002-.77 3.33 3.33 0 00.79-1.63c.7-2.52 1.39-5 2.09-7.56a4.74 4.74 0 011.72-3 4.61 4.61 0 012.44-.43 67.35 67.35 0 0111.6 1.23 4.28 4.28 0 012.53 1.13 4.45 4.45 0 01.66 3.24l-.81 14c-.45 7.92-.74 16.48 3.68 23.06l.63.16h1.16c3.32 0-2.17 8-8.68 8-4 0-10-1-10.76-5.62a7.08 7.08 0 011.19-.2c.56-.09.85-.13 1.24-.17.55-.06.52 0 .77-.08a2.49 2.49 0 001.21-.43 1.17 1.17 0 00.43-.49 1.1 1.1 0 000-.65c-.12-.54-.46-2.27-.93-4.63"/>
      {/* head 2 */}
      <path data-name="Head" d="M544.94 128.62a11.89 11.89 0 01-15.06-12.15c.12-2.12.81-4.21.57-6.32-.13-1.12-1-1.73-2-2.3a1.92 1.92 0 01.08-3.33 10 10 0 012.49-1 4.08 4.08 0 002-.77 3.33 3.33 0 00.79-1.63L536 93.6a4.74 4.74 0 011.72-3 4.61 4.61 0 012.44-.43 67.35 67.35 0 0111.6 1.23 4.28 4.28 0 012.53 1.13 4.45 4.45 0 01.66 3.24l-.81 14c-.45 7.92-.74 16.48 3.68 23.06l.63.16h1.16m-10-16.65a3.31 3.31 0 002.53-1.88 7 7 0 00.64-3.21 3.16 3.16 0 00-.63-2.27 3 3 0 00-.94-.58 1.57 1.57 0 00-.77-.16 1.46 1.46 0 00-1 .67 3.66 3.66 0 00-.7 2.43V111c-.65 0-1.93-.37-2.58-.39a.51.51 0 01-.22 0 .43.43 0 01-.13-.38v-3.44c0-.68.08-1.49.68-1.82a4.29 4.29 0 00.72-.33 1.32 1.32 0 00.35-.89 43.69 43.69 0 00.34-4.72 1.17 1.17 0 00-.21-.86 1.18 1.18 0 00-.68-.3q-5.58-1.14-11.2-2.12m4.33 39.79c.78-.15 1.59-.27 2.43-.37a4.3 4.3 0 002-.51 1.27 1.27 0 00.43-.49 1.43 1.43 0 000-.65 36.49 36.49 0 00-.93-4.63" fill="none" stroke="#fff" strokeMiterlimit="10"/>
      {/* eye */}
      <circle cx="537.13" cy="102.95" r="0.73" fill="#fff"/>
      {/* right leg bg */}
      <path d="M525.7 223.49l16.3 18.73 3.24 5.18c1.25 2.31 2.5 4.53 3.73 6.59l24.25 142.87.78 7.14 30.24.83-16.84-9.38-7.28-89.21-2.93-58.31s-1-27.73-1-28" fill="#fff"/>
      {/* right leg line */}
      <path d="M542.07 241.5c2.3 4.41 4.61 8.72 6.87 12.49l24.25 142.87.81 7.14 30.24.83-16.84-9.38-7.28-89.21-3.85-72.12c-.1-4.41-.47-9-.57-13.46" fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth=".91px"/>
      {/* left hand */}
      <path d="M461.64 188.91a31.81 31.81 0 00-11.43-4.68 1.89 1.89 0 00-1.37.08c-1.53.95 1.93 2.72 2.3 3.43-4 1-10.13 1.62-14.2 2.29a36.46 36.46 0 00-.47 5.82 211.79 211.79 0 0024.8 3.18" fill="#010101"/>
      {/* body */}
      <path d="M594.59 162.7c-.21-5.22-.45-11.13-2.39-16.58a18.13 18.13 0 00-4-6.83 13.51 13.51 0 00-1.93-1.61v-.06l-.32-.14a14.08 14.08 0 00-5.94-2.11 75.53 75.53 0 00-20.62-3h-.61l-.13.59c0 .17-1 4.18-7.66 4.62-6.81.45-9.48-2.63-9.5-2.66l-.28-.33-.42.07c-7.38 1.18-17.83 3.64-23.36 11.29-3.61 5-7.29 17.76-10.85 30.11-1.75 6.09-3.57 12.38-5.34 17.61-4.08-.71-26.78-4.62-39.52-5.82l-.83-.08v.83a33.67 33.67 0 01-.65 5.6 33.74 33.74 0 00-.67 5.85v.64l.63.1c6.66 1.08 12.82 2.53 18.78 3.94 8.81 2.08 17.13 4 26.74 4.84a21.07 21.07 0 007.76-.34c3.85-1.15 5.42-3.59 7.17-6.44.07.84.14 1.8.21 2.68.22 2.92.32 4.28.46 4.95l.74 13.89 54.28-.33-.28-19.4c4 1.14 11.83 2 15.16.9s3.66-5.23 3.67-6.48c-.02-2.6.11-26.11-.3-36.3z" fill="#39abe2"/>
      {/* arm-line left */}
      <path d="M528.21 151.93c-5.41 21.61-7.27 22.14-7.49 48.12" fill="none" stroke="#fff" strokeMiterlimit="10"/>
      {/* right hand */}
      <path d="M542.92 171.39a7.4 7.4 0 01-2.42-3.1l-4.09-7.84a.67.67 0 00-1-.2 2.79 2.79 0 00-.84 1.93 19 19 0 00.4 3 1 1 0 01-.13.77 1.12 1.12 0 01-1 .26l-15.17-.75a23.6 23.6 0 00-1.45 5.59c0 .32 14.45 6.79 21.67 8" fill="#010101"/>
      {/* arm line right */}
      <path d="M572.79 150.29c-.85 21.55 3 35.29 3 35.29-14.74-5.21-33.06-15.31-33.06-15.31l-3.54 9.2s26.86 19.59 34.89 24" fill="none" stroke="#fff" strokeMiterlimit="10"/>
    </g>
  )
}
