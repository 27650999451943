import React from 'react'

import "./feature-block.scss";

export default function FeatureBlock(props) {
  const { children } = props;
  let classes = `feature-block ${props.className}`;

  return (
    <div className={classes}>
      <figure className="feature-block__img-wrapper">
        { children.img }
      </figure>
      <h3 className="feature-block__heading">{children.heading}</h3>
      <article className="feature-block__text-content">
        {children.content}
      </article>
    </div>
  )
}

